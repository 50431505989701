* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.card-service-main-container {
  display: grid;
  justify-content: center;
  /* padding-top: 4rem; //64px */

}

.card-service-container {
  display: flex;
  gap: 1.25rem; /*20px*/
  /* color: #ebf1f4; */
  max-width: 78.75rem; /*1260px */
  height: 31.25rem; /*500px /16*/
  /* margin-top: 1rem;  */
  /*60px / 16*/
}

.card-service-container div {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.card-pics {
  width: 100%;
}

.card-img {
  width: 406px;
  height: 240px;
  border-top-left-radius: 2%;
  border-top-right-radius: 2%;
}
.card-service-shadow {
  box-shadow: 0 1.25rem 1.875rem 0px rgba(0, 0, 0, 0.2);
  border-radius: 2%;
}

.card-service-title {
  display: flex;
  padding-top: 1rem; /*24px;*/
  font-size: 1.125rem; /*18px*/
  font-weight: 600;
  font-style: italic;
  padding-left: 0.625rem; /*10px*/
}

.card-text {
  /* display: grid; */
  padding: 1rem;
  /* line-height: 1.5; */
}

.card-text-header {
  font-weight: 500;
  font-size: 1.3rem; /*98px*/
}

.card-text-content {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  margin-left: 0.5rem;
  font-size: 1rem;
}

.icon-card-title-wrapper {
  display: flex;
  /* padding-left: 0.3125rem;  */
  /*5px*/
  gap: 0.625rem; /*10px*/
  align-items: center;
}

.card-icon {
  display: flex;
  font-size: 4rem; /*98px*/
  /* padding-top: 0.5rem;  */
  /*24px;*/
}

.icon-title-wrapper {
  display: flex;
  align-items: center;
}

/* background-color: #11365f; */
/* background-color: #025497; */
/* background-color: #5584a9; */
/* background-color: rgb(239, 244, 247); */
/* text-transform: uppercase; */

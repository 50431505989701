/*
SPACING SYSTEM (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

FONT SIZE SYSTEM (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98
*/

/*
list-style: none
.class-name li: last-child
line-height: 1.1
letter-spacing: -1px
*/

/*
MAIN COLOR: 
GRAY COLOR FOR TEXT: #343a40
*/

/*
max-width: 1512px; as needed
*/

/* background-color: #11365f; */
/* background-color: #0072CE; */

* {
  margin: 0;
  padding: 0;
  font-size: 100%;
  box-sizing: border-box;
}

body {
  font-family: "Saira", sans-serif;
  color: #343a40;
  
}

.service-card-top-container {
  display: flex;
  justify-content: center;
  /* margin-top: 6.25rem; */
}
/* 
.service-card-container {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;

  font-weight: 400;
  font-size: 1.25rem; 
  justify-content: center;
  padding-left: 5px;
  align-items: center;
  background-color: #f2f7fb;
  height: 25rem; 
  width: 78.75rem;
} */

/* .service-card-top-container {
  margin-top: 3rem;
  padding-bottom: 3rem;
 
} */
/* background-color: #343a40; */
/* background-color: #5584a9;; */

.service-text-header {
  font-size: 24px;
  font-weight: 600;
  color: #343a40;
  padding-left: 10px;
}

/* .service-text-body {
  display: grid;
  margin-top: 20px;
  gap: 10px;
  font-size: 1rem;
  padding-right: 50px;
  padding-left: 10px;
} */

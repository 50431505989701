* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.change-management-main-container {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}

.change-management-inner-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 3.25rem; /*52px;*/
  /* margin-top: 4rem; */
  width: 100%;
  /* width: 78.75rem;  */
  height: 400px;
  /* justify-content: center; */
  color: #ebf1f4;
  /* background-color: #11365f; */
  /* background-color: #025497; */
  background-color: #5584a9;
}

.change-mngt-inner-main {
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  justify-content: center;
  /* margin-top: 24px; */
  /* margin-bottom: 24px; */
  gap: 20px;
}

.change-mngt-inner-main div {
  display: grid;
  max-width: 323px;
}

.our-partner-card-inner-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5px;
  width: 1090.28px;
  height: 150px;
  /* background-color: #11365f; */
}

.our-partner-card-inner-container div {
  display: grid;
  justify-content: center;
  align-items: center;
  color: #000000;
  /* background-color: #ebf1f4; */
  /* background-color: #ffffff; */
}

.under-maintena {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
}

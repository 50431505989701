.social-main-container {
  display: flex;
  justify-content: center;
}

.social-inner-container {
  /* display: grid; */
  display: flex;
  margin-top: 4rem; /*24px*/
  padding-left: 2rem;
  font-weight: 600;
  font-size: 2.25rem; /*36px*/
  justify-content: center;
  justify-items: center;
  align-items: center;
  background-color: #f2f7fb;
  height: 37.5rem; /*600px / 16 = 37.5rem;*/
  width: 78.75rem; /*1260px / 16 = 78.75rem;*/
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.our-services-top-container {
  display: flex;
  justify-content: center;
}

.our-services-main-container {
  display: flex;
  /* grid-template-columns: repeat(2, 1fr); */

  gap: 20px;
  height: 380px;
  max-width: 1090.28px;
  /* background-color: red; */
}

.our-services-text {
  /* font-size: 18px; */
  line-height: 1.7;
  height: 509.16px;
  /* margin-top: 48px; */
  /* background-color: #ebf1f4; */
}

.l3 {
  /* color: red; */
  font-size: 18px;
  font-weight: 600;
  font-style: italic;
}

.our-focus-main-container {
  display: flex;
  justify-content: center;
 
}

.our-focus-inner-containe {
  display: grid;
  gap: 20px;
  /* background-color: bisque; */
  justify-content: center;
  max-width: 1090.28px;
}
.our-focus-inner-containe div {
  display: flex;
  justify-content: center;
}

.our-service-seperator {
  margin-top: 3rem;
}

.our-focus-text {
  font-style: italic;
  max-width: 750px;
  font-size: 18px;
  line-height: 1.7;
}

.engineering-text{
  display: flex;
}
/* Only use if conflic with css doesn't allow media query work */
@media (min-width: 93em) {
  .intro-title-container {
    font-weight: 600;
    font-size: 2.25rem; /*36px*/
    height: 15.75rem; /*300px / 16 = 18.75rem*/
  }

  .footer-middle {
    width: 78.75rem;
  }

  .world-search-icon {
    display: none;
  }

  .intro-title-text {
    font-size: 1rem;
  }

  .intro-text-hide-mobile {
    font-size: 1rem;
  }

  .btn-learn-more {
    font-size: 1.125rem;
    margin-top: 1.5rem;
  }

  .intro-title-container {
    padding-top: 1rem;
  }

  .intro-title-container-div {
    padding-bottom: 1rem;
  }

  .card-service-main-container {
    padding-top: 3rem;
  }

  .service-card-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 3rem;
    padding-bottom: 3rem;
    padding-top: 3rem;

    font-weight: 400;
    font-size: 1.25rem; /*36px*/
    justify-content: center;
    /* justify-items: center; */
    padding-left: 5px;
    /* align-items: center; */
    background-color: #f2f7fb;
    /* background-color: #ffffff; */
    max-height: 25rem; /*400px / 16 = 25rem;*/
    width: 78.75rem;
    /*1260px / 16 = 78.75rem;*/
  }

  .service-text-body {
    display: grid;
    margin-top: 10px;
    gap: 25px;
    font-size: 1rem;
    padding-right: 15px;
    padding-left: 10px;
  }

  .service-text-header {
    padding-top: 1rem;
  }
}

/*************************/
/* BELOW 84*16 = 1344 px (smaller desktop)*/
/*************************/
@media (max-width: 93em) {
  .menu-container {
    /* display: grid; */
    justify-content: center;
  }
  .footer-middle {
    width: 58rem;
  }
  .comp-name {
    font-size: 20px;
  }
  .search-icon {
    display: none;
  }

  .world-icon {
    display: none;
  }

  .menu-service-dropdown,
  .menu-drop-down {
    display: none;
  }
  .menu-bar-outer-container {
    display: flex;
    width: 100%;
    font-weight: 400;

    .hero-image,
    .hero-head-container {
      width: 100%;
    }
  }

  .intro-title-container {
    font-size: 1.75rem;
    padding-left: 1rem;
    padding-top: 1.75;
    height: 10.75rem;
  }

  .intro-title-container-div {
    padding-bottom: 1rem;
  }

  .intro-text-hide-mobile {
    font-size: 1rem;
  }

  .menu-bar-logo,
  .menu-icon,
  .login-icon {
    /* font-size: 0.875rem; */
    font-size: 87.5%;
    /* color: blue; */
  }

  .card-img {
    padding: 1rem;
  }

  .service-title-container {
    /* display: grid; */
    display: grid;
    margin-top: 10rem; /*24px*/
    /* background-color: blueviolet; */
  }

  .intro-title-text {
    font-size: 1rem;
  }

  .btn-learn-more {
    font-size: 1.125rem;
    margin-top: 2rem;
  }

  .card-service-main-container {
    padding-top: 2.5rem;
  }

  .service-card-top-container {
    margin-top: 3rem;
  }

  .service-card-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 3rem;

    padding-right: 1rem;
    padding-bottom: 1.5rem;
    font-weight: 300;
    font-size: 1.25rem; /*36px*/
    justify-content: center;
    justify-items: center;
    padding-left: 5px;
    /* align-items: center; */
    background-color: #f2f7fb;
    height: 20rem; /*400px / 16 = 25rem;*/
    width: 78.75rem; /*1260px / 16 = 78.75rem;*/
  }

  .service-text-body {
    display: grid;
    margin-top: 20px;
    gap: 25px;
    font-size: 1rem;
    padding-right: 15px;
    padding-left: 10px;
  }

  .service-text-header {
    padding-top: 1.2rem;
  }
}

/*************************/
/* BELOW 83*16 = 1328 px (smaller desktop)*/
/*************************/
@media (max-width: 83em) {
  .form-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-size: 82%;
    height: auto;
  }

  .forms {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 40rem;
  }

  .form-title-inner {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    max-width: 78rem;
    /* background-color: #025497; */
  }

  .card-img {
    width: 100%;
    height: 240px;
  }

  .footer-middle {
    max-width: 78.75rem;
  }

  .intro-title-text {
    font-size: 1rem;
  }

  .btn-learn-more {
    font-size: 0.9rem;
    margin-top: 1.5rem;
  }

  .intro-title-container {
    padding-top: 1.5rem;
  }

  .card-service-main-container {
    padding-top: 2rem;
  }

  .service-card-container {
    display: grid;
    grid-template-columns: 1fr;
    height: auto;
    max-width: 800px;
  }

  .under-maintena {
    font-size: 2.5rem;
  }
}

/*************************/
/* BELOW 68*16 = 1100 px ()*/
/*************************/
@media (max-width: 68em) {
  /* .card-service-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  } */

  .intro-title-text {
    font-size: 1rem;
  }
  .card-service-main-container {
    padding-top: 1rem;
  }

  .service-card-container {
    max-width: 600px;
    /* gap: 10px; */
  }

  .under-maintena {
    font-size: 2rem;
  }
}

@media (max-width: 54em) {
  .card-service-container {
    /* color: #ebf1f4; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    font-size: 82%;
    height: auto;
  }

  .form-title-inner {
    padding: 2rem;
  }

  /* .world-icon {
    display: none;
  } */

  .search-icon {
    display: none;
  }

  .world-icon {
    display: none;
  }

  .login-icon,
  .intro-text-container {
    font-size: 87%;
  }

  .intro-title-container {
    display: grid;
    /* background-color: red; */
    /* margin: 0.625rem; */
    font-weight: 400;
    padding: 0.5rem;
    font-size: 1.1rem; /*20.8px*/
    max-height: 10.75rem;
    justify-content: center;
    align-items: center;
  }

  .card-service-main-container {
    display: flex;
  }

  .footer-middle-items {
    display: flex;
    /* background-color: red; */
  }
  .footer-middle {
    display: none;
  }

  .comp-name {
    /* font-size: 18px; */
  }

  .forms {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 20rem;
  }

  .btn-form,
  .btn-form:visited {
    width: 85px;
    height: 35px;
    font-size: 15px;
  }

  .intro-title-container-div {
    display: grid;
    font-size: 1.3rem;
    font-weight: 400;
    padding-bottom: 0.3rem;
  }

  .intro-title-text {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 0.9rem;
  }

  .intro-text-hide-mobile {
    display: none;
  }

  .btn-learn-more {
    font-size: 0.7rem;
    margin-top: 0.1rem;
  }

  .card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.4rem;
  }
  .card-service-main-container {
    max-width: 400px;
    padding: 0;
  }

  .service-card-container {
    margin-top: 0rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    max-width: 400px;
    /* gap: 10px; */
  }

  .under-maintena {
    font-size: 1rem;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.form-title-container {
  display: flex;
  justify-content: center;

  width: 100%;
  font-size: 30px;
  padding-top: 48px;
  padding-bottom: 0;
  margin-bottom: 0;
  text-transform: capitalize;
  /* background-color: red; */
}
.form-title-inner {

  /* background-color: white; */
  display: flex;
  margin-bottom: 10px;
  /* width: 1200px; */
  width: 78.75rem;
  /* background-color: #025497; */
}
.form-title-card-container {
  display: flex;
  justify-content: center;
  /* background-color: #11365f; */
}

.form-container {
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
  /* width: 78.75rem; */
  /* background-color: green; */
}

.forms {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  justify-content: center;
  /* background-color: red; */
  /* width: 1090.27px; */
  width: 78.75rem;
}

.forms div input {
  width: 100%;
  height: 62px;
  font-size: 20px;
  padding-left: 5px;
}

.forms-message {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  justify-content: center;
  /* background-color: red; */
  width: 100%;
}
.forms-message div input {
  display: grid;
  margin-top: 24px;
  /* width: 1090.27px; */
  width: 100%;
  height: 130px;
  font-size: 20px;
  padding-left: 5px;
}

.forms-button-container {
  display: flex;
  justify-content: left;
}

.btn-form,
.btn-form:visited {
  display: grid;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  width: 150px;
  height: 50px;
  font-size: 20px;
  text-transform: capitalize;
  color: #fff;
  background-color: #025497;

  /* border-radius: 25px; */
  /* text-decoration: none; */
  /* padding: 5px 10px; */
  /* display: inline-block; */
}

.btn-form:hover,
.btn-form:active {
  background-color: #0279da;
}

/* background-color: #11365f; */
/* background-color: #0259da; */

.hero-container{
  display: flex;
  /* background-color: red; */
  background-color: #5584a9;
  justify-content: center;
  align-items: center;
}

.hero-head-container {
  display: flex;
  /* grid-template-columns: 1fr; */
  color: #000;
  justify-content: center;
  justify-items: center;
  width: 94%;
  max-height: 70vh;
  /* background-color: #025497; */
  background-color: #5584a9;
}

.hero-image {
  /* z-index: 2; */
  width: 100%;
  /* max-width: 1700px; */
  max-height: 70vh;
  /* height: auto; */
  /* position: relative; */
  /* border-radius: 1%;
  border-top-left-radius: 7%;
  border-top-right-radius: 4%; */
}

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

.footer-container {
  display: grid;
  margin-top: 80px;
  justify-content: center;
  color: #ebf1f4;
  /* width: 1512px; */
  width: 100%;
  height: 747px;
  background-color: #11365f;
  /* background-color: #025497; */
}

.footer-top {
  display: flex;
  margin-top: 64px;
  justify-content: center;
  /* background-color: bisque; */
  width: 100%;
}

.footer-top-contact-us {
  display: flex;
  font-size: 24px;
  font-weight: 400;
  justify-content: center;
}

.footer-top-line {
  width: 18.75rem;
}

.footer-top-socials {
  display: flex;

  font-size: 24px;
  gap: 20px;
  margin-top: 5px;
  justify-content: center;
}

.footer-middle {
  display: flex;
  gap: 24px;
  /* width: 78.75rem; */
  justify-content: space-evenly;
  /* background-color: aqua; */
}

.footer-phone {
  display: flex;
  align-items: center;
  /* gap: 10px; */
  /* padding-top: 24px; */
}

.footer-middle-items {
  display: grid;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
}

.footer-middle-items li {
  list-style-type: none;
}

.footer-bottom {
  display: flex;
  justify-content: center;
  align-items: end;
  /* padding-bottom: 10px; */
  /* background-color: #ebf1f4; */
  width: 100%;
}
.footer-inner {
  display: flex;
  font-size: 20px;
  align-items: center;

  gap: 10px;
  margin-bottom: 18px;
}

.btn-link:link,
.btn-link:visited {
  color: #fff;
  text-decoration: none;
  padding: 5px 10px;
  display: inline-block;
}

.btn-link:hover,
.btn-link:active {
  background-color: #0279da;
  /* background-color: #11365f; */
}

.link-link:link,
.link-link:visited {
  color: #fff;
  text-decoration: none;
  padding: 5px 0px;
  display: inline-block;
}

.link-link:hover,
.link-link:active {
  background-color: #0279da;
  /* background-color: #11365f; */
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.intro-container {
  display: grid;
  /* align-items: center; */
  color: #ebf1f4;
  background-color: #5584a9;
  justify-content: center;
  max-height: 23vh;
}

.intro-title-container {
  justify-content: center;
  justify-items: center;
  height: 23vh;
}

/* .intro-title-container-div {
  padding-bottom: 1rem;
} */

.intro-title-text {
  display: grid;
  align-items: center;
  justify-items: center;
}

.btn-learn-more {
  background-color: #11365f;
  border-radius: 0.45rem;
}

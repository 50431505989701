* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.menu-bar-outer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ebf1f4;
  /* font: #11365F; */
  font-size: 1.125rem;
  font-weight: 500;
  width: auto;
  /* text-transform:uppercase; */
  /* background-color: #11365f; */
  /* background-color: #025497; */
  background-color: #5584a9;
  /* background-color: #5584a9; */
}

.menu-bar-container {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  justify-content: space-between; /*space between the elements*/
  /* color: aqua; */
  /* color: #11365F; */
  /* background-color: #025497; */
  font-size: 1.5rem;
  color: #ebf1f4;
  align-items: center;
  width: 100%;
  /* max-width: 87.5rem; 1400px */
  /* max-width: 1700px; */
  max-width: 97%;
  padding: 0.625rem; /*10px*/
  max-height: 7vh;
}

.menu-bar-logo {
  display: flex;
  justify-content: left;
  align-items: center;
}

.menu-icon {
  font-size: 1.75rem;
  font-weight: 600;
  /* margin-right: 5px; */
}

.menu-drop-down {
  margin-top: 0.625rem;
  font-size: 2rem;
}

.menu-service-dropdown {
  font-size: 1.25rem;
  margin-left: 1.25rem;
  /* fontSize: "20px", marginLeft: "20px" */
}

.comp-name {
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 500;
}
.menu-bar-search {
  display: flex;
  font-size: 2rem; /*32px*/
}

.menu-login {
  display: flex;
  justify-content: end;
  gap: 2rem; /*48px*/
}

.menu-account {
  display: flex;
}

/* .login-icon {
  font-size: 100%;
} */

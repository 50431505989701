*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.our-service-title-container{
  display: flex;
  justify-content: center;
  width: 100%; 
}

.our-services-title {
  display: flex;
  justify-content: left; 
  align-items: center;
  justify-items: center;
  margin-bottom: 0.3125rem; /*5px*/
  margin-top: 3rem;
  font-size: 2rem;
  font-weight: 600;
  width: 100%;
  width: 68.141875rem; 
  /*1090.27px*/
  /* font-style: italic;  */
  /* background-color: red; */
}

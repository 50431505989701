/* rem and em do NOT depend on html font-size un media queries!
Instead, 1rem = 1em =16px*/

/*************************/
/* BELOW 84*16 = 1344 px (smaller desktop)*/
/*************************/

@media (max-width: 84em) {
  .hero-image {
    max-width: 71.25rem;
    max-height: 70vh;
  }
}
